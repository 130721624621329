import quotes from "../quotes.json";

type IconsSectionProps = {
  orientation: "left" | "middle";
};

const IconsSection = (props: IconsSectionProps) => {
  return (
    <div className={`icons-section ${props.orientation}`}>
      <a href="mailto:killian@thisishomeland.com">
        <img
          style={{
            height: "30px",
            width: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          src={require("../images/Email.png")}
          alt="email"
        />
      </a>

      <a href="https://calendly.com/killian-dunne/30min">
        <img
          style={{
            height: "30px",
            width: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          src={require("../images/Calendar.png")}
          alt="calendar"
        />
      </a>

      <a href="tel: +353851432789">
        <img
          style={{
            height: "30px",
            width: "30px",
            margin: "10px",
            cursor: "pointer",
          }}
          src={require("../images/WhatsApp.png")}
          alt="whatsapp"
        />
      </a>
    </div>
  );
};

const IndexPage: React.FC<any> = () => {
  return (
    <main>
      <div className="black">
        <div className="heading-container">
          <img
            className="headshot"
            src={require("../images/headshot.png")}
            alt={"headshot"}
            height={300}
            width={300}
          />
          <h1 className="title">Killian Dunne</h1>
          <div className="subtitle">Startup obsessive</div>
        </div>
        <IconsSection orientation="left" />
      </div>
      <div className="list">
        <div style={{ paddingLeft: 2, paddingRight: 4 }}>
          {quotes.map((quote, idx) => (
            <span
              key={idx}
              className="quote"
              style={{
                color:
                  quote === "Persistence beats everything"
                    ? "#000"
                    : idx % 2 === 0
                    ? "#C7C8C3"
                    : "#8e9089",
                fontWeight:
                  quote === "Persistence beats everything" ? 800 : 400,
              }}
            >
              {" " + quote}
            </span>
          ))}
        </div>
      </div>
      <IconsSection orientation="middle" />
    </main>
  );
};

export default IndexPage;
